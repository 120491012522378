
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'className': 'cm_icon',
                'xmlns': 'http://www.w3.org/2000/svg',
                'viewBox': '0 0 20 20'
            }, _createElement('path', { 'd': 'M18.07,9.04C18.07,4.05,14.02,0,9.04,0S0,4.05,0,9.04s4.05,9.04,9.04,9.04c2.08,0,4-.72,5.53-1.91l3.84,3.84,1.6-1.6-3.84-3.84c1.19-1.53,1.91-3.45,1.91-5.53ZM9.04,15.81c-3.74,0-6.78-3.04-6.78-6.78s3.04-6.78,6.78-6.78,6.78,3.04,6.78,6.78-3.04,6.78-6.78,6.78Z' })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]