
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function verifyFitmentRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3521'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3723'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7541'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7544'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'key': '2843'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', '', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    function repeatSplittedValue2(verifyFitmentInfo, value, valueIndex, splittedValue, splittedValueIndex) {
        return [_createElement('li', { 'key': '119421' }, splittedValue)];
    }
    function repeatValue3(verifyFitmentInfo, value, valueIndex) {
        return [_map(value.split('\n'), repeatSplittedValue2.bind(this, verifyFitmentInfo, value, valueIndex))];
    }
    function repeatSplittedValue4(verifyFitmentInfo, key, keyIndex, value, valueIndex, splittedValue, splittedValueIndex) {
        return [_createElement('li', { 'key': '125661' }, splittedValue)];
    }
    function repeatValue5(verifyFitmentInfo, key, keyIndex, value, valueIndex) {
        return [_map(value.split('\n'), repeatSplittedValue4.bind(this, verifyFitmentInfo, key, keyIndex, value, valueIndex))];
    }
    function repeatKey6(verifyFitmentInfo, key, keyIndex) {
        return [_createElement('div', {
                'className': 'cm_verify-fitment_vehicle-info',
                'key': '121531'
            }, _createElement('div', { 'className': 'cm_verify-fitment_vehicle-info__key' }, key, ':'), _createElement.apply(this, [
                'ul',
                { 'className': 'cm_verify-fitment_vehicle-info__values' },
                _map(verifyFitmentInfo[key], repeatValue5.bind(this, verifyFitmentInfo, key, keyIndex))
            ]))];
    }
    function scopeVerifyFitmentInfo7() {
        var verifyFitmentInfo = this.productData?.verify_fitment_info;
        return [verifyFitmentInfo && Object.keys(verifyFitmentInfo).length > 0 ? _createElement.apply(this, [
                'div',
                {
                    'className': 'cm_verify-fitment_vehicle-info__container',
                    'key': '114991'
                },
                verifyFitmentInfo['Notes'] ? _createElement.apply(this, [
                    'ul',
                    {
                        'className': 'cm_verify-fitment_vehicle-notes',
                        'key': '11747'
                    },
                    _map(verifyFitmentInfo['Notes'], repeatValue3.bind(this, verifyFitmentInfo))
                ]) : null,
                _map(Object.keys(verifyFitmentInfo).filter(v => v !== 'Notes'), repeatKey6.bind(this, verifyFitmentInfo))
            ]) : null];
    }
    function repeatSelects8(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3521'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3723'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7541'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7544'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'key': '2843'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', '', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__verify-fitment' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '68'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.verifyFitmentTitle || 'Verify fitment with your vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go btn btn--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    GO\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear btn btn--primary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle,
        'role': 'button',
        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
    }, [_createElement('svg', {
            'xmlns': 'http://www.w3.org/2000/svg',
            'className': 'cm_icon cm_icon-reset',
            'height': '16px',
            'viewBox': '0 0 16 16',
            'key': '67060'
        }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__' + this.fits + ' cmTemplate_locked',
        'key': '7231'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment__container' }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 492 492',
            'key': '74830'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 22 22',
            'key': '81740'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))] : null, this.fits === 'universal' ? [_createElement('svg', {
            'width': '40',
            'height': '40',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 20 20',
            'key': '83660'
        }, _createElement('path', {
            'className': 'cls-1',
            'd': 'M19.41,6.64s-.01-.03-.02-.05C17.99,2.75,14.32,0,10,0S2.01,2.75.61,6.59c0,.02-.01.04-.02.05-.38,1.05-.59,2.18-.59,3.35s.22,2.3.59,3.35c0,.02.01.04.02.05,1.4,3.84,5.07,6.59,9.39,6.59.55,0,1-.45,1-1s-.45-1-1-1c-.12,0-.24-.01-.35-.02-.56-1.3-.98-2.67-1.25-4.06.36-.15.61-.51.61-.92,0-.51-.39-.92-.89-.98-.07-.67-.11-1.35-.11-2.02s.04-1.33.1-2h3.79s0,.06.01.09c.05.52.48.91,1,.91.03,0,.06,0,.09,0,.52-.05.89-.49.89-1h3.85c.17.64.26,1.31.26,2,0,.55.45,1,1,1s1-.45,1-1c0-1.18-.22-2.31-.59-3.36ZM7.32,2.47c-.41,1.15-.73,2.33-.95,3.53h-3.29c.94-1.63,2.44-2.89,4.24-3.53ZM3.08,14h3.29c.22,1.2.54,2.38.95,3.53-1.8-.64-3.29-1.9-4.24-3.53ZM6.1,12h-3.84c-.17-.64-.26-1.31-.26-2s.1-1.36.26-2h3.84c-.06.67-.1,1.33-.1,2s.04,1.33.1,2ZM8.41,6c.28-1.37.69-2.7,1.24-3.98.12,0,.23-.02.35-.02s.23.01.34.02c.55,1.29.97,2.62,1.24,3.98h-3.18ZM13.63,6c-.22-1.2-.53-2.37-.95-3.53,1.79.64,3.29,1.9,4.23,3.53h-3.28Z',
            'fill': 'black',
            'strokeWidth': '0'
        }), _createElement('path', {
            'className': 'cls-1',
            'd': 'M17.43,16.02c.35-.59.57-1.28.57-2.02,0-2.21-1.79-4-4-4s-4,1.79-4,4,1.79,4,4,4c.74,0,1.42-.22,2.02-.57l1.27,1.27c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02,0-1.41l-1.27-1.27ZM12,14c0-1.1.9-2,2-2s2,.9,2,2c0,.55-.22,1.05-.58,1.41,0,0,0,0,0,0,0,0,0,0,0,0-.36.36-.86.58-1.41.58-1.1,0-2-.9-2-2Z',
            'fill': 'black',
            'strokeWidth': '0'
        }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'width': '40',
            'height': '40',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 20 20',
            'key': '98240'
        }, _createElement('path', {
            'className': 'cls-1',
            'd': 'M10,7c.06,0,.13,0,.19-.02.07,0,.13-.03.19-.06.06-.02.12-.05.17-.09.06-.04.11-.08.15-.12.05-.05.09-.1.13-.15.04-.06.07-.12.09-.18.03-.06.05-.12.06-.18.01-.07.02-.13.02-.2,0-.26-.11-.52-.3-.71-.04-.04-.09-.09-.15-.12-.05-.04-.11-.07-.17-.09-.06-.03-.12-.05-.19-.06-.12-.03-.26-.03-.39,0-.06.01-.12.03-.18.06-.06.02-.12.05-.18.09-.05.03-.1.08-.15.12-.09.09-.16.2-.22.33-.05.12-.07.25-.07.38,0,.27.11.52.29.71.19.18.44.29.71.29Z',
            'fill': 'black',
            'strokeWidth': '0'
        }), _createElement('path', {
            'className': 'cls-1',
            'd': 'M10,0C4.49,0,0,4.49,0,10s4.49,10,10,10,10-4.49,10-10S15.51,0,10,0ZM10,2c1.85,0,3.54.63,4.9,1.69l-4.44,4.44c-.14-.07-.29-.13-.46-.13-.55,0-1,.45-1,1v.59l-5.31,5.31c-1.05-1.36-1.69-3.05-1.69-4.9C2,5.59,5.59,2,10,2ZM10,18c-1.85,0-3.54-.63-4.9-1.69l3.9-3.9v1.59c0,.55.45,1,1,1s1-.45,1-1v-3.59l5.31-5.31c1.05,1.36,1.69,3.05,1.69,4.9,0,4.41-3.59,8-8,8Z',
            'fill': 'black',
            'strokeWidth': '0'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, this.fits === 'yes' ? ['This Product Fits Your'] : null, this.fits === 'no' ? ['This Product DOES NOT Fit Your'] : null), _createElement('div', { 'className': 'cm_verify-fitment_body' }, _createElement('span', { 'className': 'cm_verify-fitment_vehicle' }, this.vehicleString || 'Vehicle'), _createElement('a', {
        'className': 'cm_verify-fitment_change',
        'onClick': this.changeVehicle
    }, 'Change')), this.fits === 'yes' ? [scopeVerifyFitmentInfo7.apply(this, [])] : null, this.fits === 'no' ? _createElement('div', {
        'className': 'cm_verify-fitment_compatible-parts-container',
        'key': '12903'
    }, _createElement('a', {
        'href': this.getVehicleUrl(),
        'className': 'cm_verify-fitment_compatible-parts'
    }, '\n            Click here for products that do fit your vehicle\n          ')) : null))) : null, this.template === 'inexact' ? _createElement('div', {
        'className': 'cm_vehicle-widget_specifier cmTemplate_inexact',
        'key': '13212'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, 'Please specify ', this.selectFields.join(', '), ' for your ', this.vehicleString), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects8.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button button button--primary btn btn--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    GO\n  ')))) : null, this.template === 'universal' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__universal cmTemplate_universal',
        'key': '19608'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 492 492',
            'key': '198070'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 22 22',
            'key': '204980'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))] : null, this.fits === 'universal' ? [_createElement('svg', {
            'width': '40',
            'height': '40',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 20 20',
            'key': '206900'
        }, _createElement('path', {
            'className': 'cls-1',
            'd': 'M19.41,6.64s-.01-.03-.02-.05C17.99,2.75,14.32,0,10,0S2.01,2.75.61,6.59c0,.02-.01.04-.02.05-.38,1.05-.59,2.18-.59,3.35s.22,2.3.59,3.35c0,.02.01.04.02.05,1.4,3.84,5.07,6.59,9.39,6.59.55,0,1-.45,1-1s-.45-1-1-1c-.12,0-.24-.01-.35-.02-.56-1.3-.98-2.67-1.25-4.06.36-.15.61-.51.61-.92,0-.51-.39-.92-.89-.98-.07-.67-.11-1.35-.11-2.02s.04-1.33.1-2h3.79s0,.06.01.09c.05.52.48.91,1,.91.03,0,.06,0,.09,0,.52-.05.89-.49.89-1h3.85c.17.64.26,1.31.26,2,0,.55.45,1,1,1s1-.45,1-1c0-1.18-.22-2.31-.59-3.36ZM7.32,2.47c-.41,1.15-.73,2.33-.95,3.53h-3.29c.94-1.63,2.44-2.89,4.24-3.53ZM3.08,14h3.29c.22,1.2.54,2.38.95,3.53-1.8-.64-3.29-1.9-4.24-3.53ZM6.1,12h-3.84c-.17-.64-.26-1.31-.26-2s.1-1.36.26-2h3.84c-.06.67-.1,1.33-.1,2s.04,1.33.1,2ZM8.41,6c.28-1.37.69-2.7,1.24-3.98.12,0,.23-.02.35-.02s.23.01.34.02c.55,1.29.97,2.62,1.24,3.98h-3.18ZM13.63,6c-.22-1.2-.53-2.37-.95-3.53,1.79.64,3.29,1.9,4.23,3.53h-3.28Z',
            'fill': 'black',
            'strokeWidth': '0'
        }), _createElement('path', {
            'className': 'cls-1',
            'd': 'M17.43,16.02c.35-.59.57-1.28.57-2.02,0-2.21-1.79-4-4-4s-4,1.79-4,4,1.79,4,4,4c.74,0,1.42-.22,2.02-.57l1.27,1.27c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02,0-1.41l-1.27-1.27ZM12,14c0-1.1.9-2,2-2s2,.9,2,2c0,.55-.22,1.05-.58,1.41,0,0,0,0,0,0,0,0,0,0,0,0-.36.36-.86.58-1.41.58-1.1,0-2-.9-2-2Z',
            'fill': 'black',
            'strokeWidth': '0'
        }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'width': '40',
            'height': '40',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 20 20',
            'key': '221480'
        }, _createElement('path', {
            'className': 'cls-1',
            'd': 'M10,7c.06,0,.13,0,.19-.02.07,0,.13-.03.19-.06.06-.02.12-.05.17-.09.06-.04.11-.08.15-.12.05-.05.09-.1.13-.15.04-.06.07-.12.09-.18.03-.06.05-.12.06-.18.01-.07.02-.13.02-.2,0-.26-.11-.52-.3-.71-.04-.04-.09-.09-.15-.12-.05-.04-.11-.07-.17-.09-.06-.03-.12-.05-.19-.06-.12-.03-.26-.03-.39,0-.06.01-.12.03-.18.06-.06.02-.12.05-.18.09-.05.03-.1.08-.15.12-.09.09-.16.2-.22.33-.05.12-.07.25-.07.38,0,.27.11.52.29.71.19.18.44.29.71.29Z',
            'fill': 'black',
            'strokeWidth': '0'
        }), _createElement('path', {
            'className': 'cls-1',
            'd': 'M10,0C4.49,0,0,4.49,0,10s4.49,10,10,10,10-4.49,10-10S15.51,0,10,0ZM10,2c1.85,0,3.54.63,4.9,1.69l-4.44,4.44c-.14-.07-.29-.13-.46-.13-.55,0-1,.45-1,1v.59l-5.31,5.31c-1.05-1.36-1.69-3.05-1.69-4.9C2,5.59,5.59,2,10,2ZM10,18c-1.85,0-3.54-.63-4.9-1.69l3.9-3.9v1.59c0,.55.45,1,1,1s1-.45,1-1v-3.59l5.31-5.31c1.05,1.36,1.69,3.05,1.69,4.9,0,4.41-3.59,8-8,8Z',
            'fill': 'black',
            'strokeWidth': '0'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, window.Convermax.config?.verifyFitmentUniversalTitle || 'Universal Fit'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, 'This is a Universal Product. Please contact us to confirm fitment.'))) : null, this.template === 'unknown' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__unknown cmTemplate_unknown',
        'key': '23514'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 492 492',
            'key': '237070'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 22 22',
            'key': '243980'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))] : null, this.fits === 'universal' ? [_createElement('svg', {
            'width': '40',
            'height': '40',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 20 20',
            'key': '245900'
        }, _createElement('path', {
            'className': 'cls-1',
            'd': 'M19.41,6.64s-.01-.03-.02-.05C17.99,2.75,14.32,0,10,0S2.01,2.75.61,6.59c0,.02-.01.04-.02.05-.38,1.05-.59,2.18-.59,3.35s.22,2.3.59,3.35c0,.02.01.04.02.05,1.4,3.84,5.07,6.59,9.39,6.59.55,0,1-.45,1-1s-.45-1-1-1c-.12,0-.24-.01-.35-.02-.56-1.3-.98-2.67-1.25-4.06.36-.15.61-.51.61-.92,0-.51-.39-.92-.89-.98-.07-.67-.11-1.35-.11-2.02s.04-1.33.1-2h3.79s0,.06.01.09c.05.52.48.91,1,.91.03,0,.06,0,.09,0,.52-.05.89-.49.89-1h3.85c.17.64.26,1.31.26,2,0,.55.45,1,1,1s1-.45,1-1c0-1.18-.22-2.31-.59-3.36ZM7.32,2.47c-.41,1.15-.73,2.33-.95,3.53h-3.29c.94-1.63,2.44-2.89,4.24-3.53ZM3.08,14h3.29c.22,1.2.54,2.38.95,3.53-1.8-.64-3.29-1.9-4.24-3.53ZM6.1,12h-3.84c-.17-.64-.26-1.31-.26-2s.1-1.36.26-2h3.84c-.06.67-.1,1.33-.1,2s.04,1.33.1,2ZM8.41,6c.28-1.37.69-2.7,1.24-3.98.12,0,.23-.02.35-.02s.23.01.34.02c.55,1.29.97,2.62,1.24,3.98h-3.18ZM13.63,6c-.22-1.2-.53-2.37-.95-3.53,1.79.64,3.29,1.9,4.23,3.53h-3.28Z',
            'fill': 'black',
            'strokeWidth': '0'
        }), _createElement('path', {
            'className': 'cls-1',
            'd': 'M17.43,16.02c.35-.59.57-1.28.57-2.02,0-2.21-1.79-4-4-4s-4,1.79-4,4,1.79,4,4,4c.74,0,1.42-.22,2.02-.57l1.27,1.27c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02,0-1.41l-1.27-1.27ZM12,14c0-1.1.9-2,2-2s2,.9,2,2c0,.55-.22,1.05-.58,1.41,0,0,0,0,0,0,0,0,0,0,0,0-.36.36-.86.58-1.41.58-1.1,0-2-.9-2-2Z',
            'fill': 'black',
            'strokeWidth': '0'
        }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'width': '40',
            'height': '40',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 20 20',
            'key': '260480'
        }, _createElement('path', {
            'className': 'cls-1',
            'd': 'M10,7c.06,0,.13,0,.19-.02.07,0,.13-.03.19-.06.06-.02.12-.05.17-.09.06-.04.11-.08.15-.12.05-.05.09-.1.13-.15.04-.06.07-.12.09-.18.03-.06.05-.12.06-.18.01-.07.02-.13.02-.2,0-.26-.11-.52-.3-.71-.04-.04-.09-.09-.15-.12-.05-.04-.11-.07-.17-.09-.06-.03-.12-.05-.19-.06-.12-.03-.26-.03-.39,0-.06.01-.12.03-.18.06-.06.02-.12.05-.18.09-.05.03-.1.08-.15.12-.09.09-.16.2-.22.33-.05.12-.07.25-.07.38,0,.27.11.52.29.71.19.18.44.29.71.29Z',
            'fill': 'black',
            'strokeWidth': '0'
        }), _createElement('path', {
            'className': 'cls-1',
            'd': 'M10,0C4.49,0,0,4.49,0,10s4.49,10,10,10,10-4.49,10-10S15.51,0,10,0ZM10,2c1.85,0,3.54.63,4.9,1.69l-4.44,4.44c-.14-.07-.29-.13-.46-.13-.55,0-1,.45-1,1v.59l-5.31,5.31c-1.05-1.36-1.69-3.05-1.69-4.9C2,5.59,5.59,2,10,2ZM10,18c-1.85,0-3.54-.63-4.9-1.69l3.9-3.9v1.59c0,.55.45,1,1,1s1-.45,1-1v-3.59l5.31-5.31c1.05,1.36,1.69,3.05,1.69,4.9,0,4.41-3.59,8-8,8Z',
            'fill': 'black',
            'strokeWidth': '0'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, 'No Fitment Data'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, 'Unfortunately this product does not have any fitment information at the moment. Please contact us to confirm fitment.'))) : null);
}
        export const componentNames = ["cm:filterInput","cm:filterInput"]