//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-548:_2956,_4536,_6108,_4764,_2260,_316,_248,_6556;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-548')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-548', "_2956,_4536,_6108,_4764,_2260,_316,_248,_6556");
        }
      }catch (ex) {
        console.error(ex);
      }