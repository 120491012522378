
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('product-card', { 'class': 'card card--product h-full card--product-contained relative flex' }, _createElement('div', { 'className': 'card__media image-blend relative' }, _createElement('a', {
                    'href': this.url,
                    'aria-label': this.removeHTML(this.title),
                    'className': 'media block relative image-blend'
                }, _createElement('img', {
                    'src': this.resizeImage(this.image),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image'),
                    'className': 'img-fit img-fit--contain card__main-image lazyautosizes lazyloaded'
                })), _createElement('div', { 'className': 'product-label-container absolute top-0 start' }, _createElement('div', { 'className': 'product-label-wrapper flex' }, this.compare_at_price && this.compare_at_price > this.price ? _createElement('span', {
                    'className': 'product-label product-label--sale',
                    'key': '594'
                }, '\n          ', Math.round((this.compare_at_price - this.price) * 100 / this.compare_at_price), '% off\n        ') : null))), _createElement('div', { 'className': 'card__info-container flex flex-col flex-auto relative' }, _createElement('div', { 'className': 'card__info w-full' }, _createElement('div', { 'className': 'card__info-inner flex flex-col h-full w-full' }, _createElement('p', mergeProps({ 'className': 'card__vendor mb-1 text-sm text-theme-light' }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('p', { 'className': 'card__title font-bold mb-1 font-heading h6' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'card-link text-current'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), this.price > 50 ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'data-learnmore-show': 'false',
                    'key': '1375'
                }) : null, _createElement('div', { 'className': 'card--product__inv-shipping-est-container' }, _createElement('div', { 'className': 'product-inventory__status-container' }, this.stock_status ? _createElement('div', {
                    'className': 'mt-3 border-top pt-3 text-xs product-inventory__status ' + this.stock_status,
                    'key': '1678'
                }, '\n              ', this.stock_status === 'normal' ? 'In Stock' : this.stock_status === 'low' ? 'Low stock' : this.stock_status === 'very-low' ? 'Very low stock' : 'Out of stock', '\n            ') : null), this.free_fast_shipping ? _createElement('div', {
                    'className': 'card--product__shipping text-xs fw-bold',
                    'data-text-type': 'freeship',
                    'key': '2098'
                }, _createElement('svg', {
                    'width': '16',
                    'height': '8',
                    'viewBox': '0 0 16 8',
                    'fill': 'none'
                }, _createElement('path', {
                    'fillRule': 'evenodd',
                    'clipRule': 'evenodd',
                    'd': 'M14.5318 3.73077L15.6104 3.94939H15.6105C15.8462 3.99992 16.0148 4.20148 15.999 4.4365V6.05016C15.999 6.31932 15.7797 6.55434 15.4933 6.55434H14.9368C14.9368 7.34477 14.2961 7.99994 13.4868 7.99994C12.694 7.99994 12.0368 7.36117 12.0368 6.55434H8.15952C8.15952 7.34477 7.50167 7.99994 6.7095 7.99994C5.91665 7.99994 5.25947 7.36117 5.25947 6.55434H4.70304C4.43304 6.55434 4.19731 6.33573 4.19731 6.05016V0.504124C4.19731 0.234956 4.41659 -6.10352e-05 4.70304 -6.10352e-05H11.396C11.666 -6.10352e-05 11.9018 0.218553 11.9018 0.504124V1.29388H12.7618C13.3347 1.29388 13.8404 1.66348 14.0261 2.20114L14.5318 3.73077ZM6.018 6.55441C6.018 6.94108 6.3387 7.24373 6.70943 7.24373C7.09729 7.24373 7.40021 6.94108 7.40086 6.55441C7.40086 6.16773 7.09729 5.86509 6.70943 5.86509C6.32157 5.86509 6.018 6.18481 6.018 6.55441ZM11.9018 2.05093V3.59696H13.6889L13.3011 2.43761C13.2168 2.20192 12.9975 2.05093 12.7618 2.05093H11.9018ZM12.8118 6.55441C12.8118 6.94108 13.1325 7.24373 13.5032 7.24373C13.874 7.24373 14.1775 6.94108 14.1947 6.55441C14.1947 6.16773 13.874 5.86509 13.5032 5.86509C13.1154 5.86509 12.8118 6.18481 12.8118 6.55441ZM0.404314 1.17637H2.96716C3.2029 1.17637 3.37148 1.36149 3.37082 1.57945C3.37082 1.79739 3.18579 1.98253 2.96651 1.98253H0.404314C0.185704 1.98253 0 1.79806 0 1.57945C0 1.36151 0.185029 1.17637 0.404314 1.17637ZM2.96715 2.87413H1.04506C0.825776 2.87413 0.640747 3.05926 0.640747 3.2772C0.640747 3.49582 0.826451 3.68028 1.04506 3.68028H2.96649C3.18578 3.68028 3.37081 3.49515 3.37081 3.2772C3.38859 3.04219 3.20289 2.87413 2.96715 2.87413ZM1.68573 4.55463H2.96718C3.20291 4.55463 3.37149 4.73909 3.37149 4.95771C3.37149 5.17565 3.18646 5.36079 2.96718 5.36079H1.68573C1.46712 5.36079 1.28141 5.17632 1.28141 4.95771C1.28141 4.73976 1.46644 4.55463 1.68573 4.55463Z'
                })), '\n            ', this.price && this.price > 99 ? 'Free Fast Shipping' : 'Fast Shipping', '\n          ') : null, this.free_fast_shipping && this.price && this.price > 99 ? _createElement('x-estimated-date', { 'key': '4297' }, _createElement('p', {}, '\n              Get it by\n              ', _createElement('time', {}, window.Convermax.getDeliveryDates().minDate), '\n              -\n              ', _createElement('time', {}, window.Convermax.getDeliveryDates().maxDate))) : null), _createElement('div', { 'className': 'flex grow items-end' }, _createElement('div', { 'className': 'price price--bottom price--on-sale' }, _createElement('div', { 'className': 'price__default' }, _createElement('strong', { 'className': 'price__current' }, this.price_varies ? _createElement('span', { 'key': '4868' }, 'From: ') : null, this.formatPrice(this.price), '\n              '), this.on_sale ? _createElement('s', {
                    'className': 'price__was',
                    'key': '4982'
                }, this.formatPrice(this.compare_at_price)) : null))))), _createElement('div', { 'className': 'mob:card__quick-add--below desktop:card__quick-add--below add-to-cart' }, _createElement('product-form', {}, _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'cm_product-item__buttons-form',
                    'id': this.id,
                    'encType': 'multipart/form-data'
                }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'className': 'cm_button cm_button__secondary',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '53961'
                    }, '\n              ', this.out_of_stock ? 'Sold out' : 'Select options', '\n            ')] : null, this.variant_ids.length === 1 ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '56781'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '56783'
                    }),
                    _createElement('button', {
                        'className': 'btn btn--primary w-full',
                        'type': 'submit',
                        'data-form-id': this.id,
                        'disabled': this.out_of_stock,
                        'key': '56785'
                    }, '\n              ', this.out_of_stock ? 'Sold out' : 'Add to cart', '\n            ')
                ] : null)))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []