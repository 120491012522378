import shopifyGenericDefaults from '../shopify-generic/config.js';

window.Convermax.getDeliveryDates = (minDays = 2, maxDays = 4) => {
  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };

  const addBusinessDays = (date, days) => {
    let count = 0;
    while (count < days) {
      date.setDate(date.getDate() + 1);
      const dayOfWeek = date.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
    }
    return date;
  };

  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const getFormattedDate = (days) => {
    const date = new Date();
    addBusinessDays(date, days);
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    const daySuffix = getDaySuffix(date.getDate());
    return `${formattedDate}${daySuffix}`;
  };

  return {
    minDate: getFormattedDate(minDays),
    maxDate: getFormattedDate(maxDays),
  };
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['SearchBox', 'MobileGarage', 'Garage', 'VerifyFitment'].includes(w.name),
    ),
    {
      name: 'SearchBox',
      location: { selector: '#cm-search-box', class: 'cm-search-box' },
      onDropdownItemsReceived: () =>
        shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchBox').onDropdownItemsReceived,
      template: 'SearchBox',
    },
    {
      name: 'HeaderVehicleWidget_garage',
      type: 'VehicleWidget',
      location: '#cm-garage',
      template: 'fitmentSearch/garageVehicleWidget',
      isAlwaysColumnLayout: true,
      initCollapsed: true,
    },
    {
      name: 'HeaderVehicleWidget_mobile-garage',
      type: 'VehicleWidget',
      location: '#cm-mobile-garage',
      template: 'fitmentSearch/garageVehicleWidget',
      isAlwaysColumnLayout: true,
      initCollapsed: true,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/collapsibleVerifyFitment',
      isAlwaysColumnLayout: true,
      initCollapsed: true,
    },
  ],
};
